<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <v-row class="auth-row ma-0">
                <v-col lg="6"
                       class="d-none d-lg-block position-relative overflow-hidden pa-0">
                    <div class="auth-illustrator-wrapper login-bg"> </div>
                </v-col>

                <v-col lg="6"
                       style="overflow: auto;height: 100vh;"
                       class="auth-bg pa-lg-10 pa-md-10 pa-sm-4 pb-0 pt-10">

                    <v-row style="justify-content: end">
                        <div class="d-flex align-right right-row">
                            <app-bar-i18n></app-bar-i18n>
                            <!-- <v-icon>mdi-power-on</v-icon> -->
                            <!-- <app-bar-theme-switcher></app-bar-theme-switcher> -->
                        </div>
                    </v-row>

                    <v-row>

                        <v-col cols="12"
                               sm="8"
                               md="6"
                               lg="12"
                               class="mx-auto">
                            <div v-if="$vuetify.breakpoint.smAndDown">
                                <v-img contain
                                       max-width="100%"
                                       class="auth-3d-group overflow-hidden"
                                       :src="require(`@/assets/images/banner.png`)">
                                </v-img>
                            </div>
                            <v-card flat>
                                <p class="text-center text-2xl font-weight-semibold text-black pt-3"
                                   style="margin-bottom: 0;margin: 0 24px;position: relative;">
                                    <v-icon class="arabic-arrow ar-forgotid en-forgotid"
                                            color="green-darken-2"
                                            @click="backStep">
                                        mdi-arrow-left
                                    </v-icon>{{ $t('Forgot User ID') }}
                                </p>
                                <v-stepper v-model="step"
                                           class="custom"
                                           style="background: transparent;">
                                    <v-stepper-items>
                                        <v-stepper-content step="1">
                                            <ForgotStepOne @submit="nextStep($event)"
                                                           @back="backToSignIn()"></ForgotStepOne>
                                        </v-stepper-content>

                                        <v-stepper-content step="2">
                                            <ForgotStepTwo @submit="nextStep($event)"
                                                           @back="backStep($event)"
                                                           v-if="step == '2'"></ForgotStepTwo>
                                        </v-stepper-content>

                                        <v-stepper-content step="3">
                                            <ForgotStepThree @submit="finishedForgot($event)"
                                                             @back="backStep($event)"
                                                             v-if="step == '3'"></ForgotStepThree>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>

                                <div class="d-flex align-center justify-space-around flex-wrap font-weight-bold pt-10">
                                    <div style="cursor: pointer; color: #016fd0 !important"
                                         @click="publicContact = true"
                                         class="arabic-font text-center text-blue">
                                        <v-img contain
                                               max-width="20px"
                                               class="auth-3d-group overflow-hidden mx-auto mb-2"
                                               :src="require(`@/assets/images/login/phone.png`)">
                                        </v-img>
                                        {{ $t('Contact Us') }}
                                    </div>
                                    <div style="cursor: pointer; color: #016fd0 !important"
                                         @click="modalTerms = true"
                                         class="arabic-font text-center text-blue">
                                        <v-img contain
                                               max-width="20px"
                                               class="auth-3d-group overflow-hidden mx-auto mb-2"
                                               :src="require(`@/assets/images/login/Vector.png`)">
                                        </v-img>
                                        {{ $t('Terms of Use') }}
                                    </div>

                                    <v-dialog v-model="modalTerms"
                                              width="80%">
                                        <v-card>
                                            <v-card-title>
                                                <span class="text-h5 text-color-black">
                                                    {{ $t('Terms of Use') }}
                                                </span>
                                                <v-spacer></v-spacer>
                                                <span style="cursor: pointer"
                                                      @click="modalTerms = false"
                                                      class="arabic-close en-close">
                                                    <v-icon>
                                                        {{ icons.mdiClose }}
                                                    </v-icon>
                                                </span>
                                            </v-card-title>

                                            <v-tabs v-model="tab"
                                                    dark>
                                                <v-tab>
                                                    {{ $t(`Terms & Conditions`) }}
                                                </v-tab>
                                                <v-tab>
                                                    {{ $t('Privacy Statement') }}
                                                </v-tab>
                                            </v-tabs>
                                            <v-tabs-items v-model="tab">
                                                <v-tab-item>
                                                    <TermConditionAr v-if="selectedLanguage == 'ar'">
                                                    </TermConditionAr>
                                                    <TermConditionEn v-if="selectedLanguage == 'en'">
                                                    </TermConditionEn>
                                                </v-tab-item>
                                                <v-tab-item>
                                                    <PrivacyTranslationAr v-if="selectedLanguage == 'ar'">
                                                    </PrivacyTranslationAr>
                                                    <PrivacyTranslationEn v-if="selectedLanguage == 'en'">
                                                    </PrivacyTranslationEn>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-card>
                                    </v-dialog>

                                    <v-dialog v-model="publicContact"
                                              style="overflow: hidden;">
                                        <v-card class="public-contact">
                                            <v-card-title>
                                                <span class="text-h5 text-color-black">
                                                    {{ $t(`Contact Us`) }}
                                                </span>
                                                <v-spacer></v-spacer>
                                                <span style="cursor: pointer"
                                                      @click="publicContact = false"
                                                      class="arabic-close en-close">
                                                    <v-icon>
                                                        {{ icons.mdiClose }}
                                                    </v-icon>
                                                </span>
                                            </v-card-title>

                                            <v-card-text>
                                                <PublicContact></PublicContact>

                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                </div>

                                <p class="text--primary mb-2 pt-3 text-center"
                                   style="font-size: 12px;">
                                    {{ $t(`public_auth_long_text1`) }} <br><br>
                                    {{ $t(`public_auth_long_text`) }}
                                </p>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import themeConfig from '@themeConfig'
// import TermsCondition from '@/views/TermsCondition.vue';

import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'

import router from '@/router'

import ForgotStepOne from './ForgotStepOne.vue';
import ForgotStepTwo from './ForgotStepTwo.vue';
import ForgotStepThree from './ForgotStepThree';

import TermConditionAr from './term-and-policy/terms-condition-translation/TermConditionAr.vue';
import TermConditionEn from './term-and-policy/terms-condition-translation/TermConditionEn.vue';

import PrivacyTranslationAr from './term-and-policy/privacy-policy-translation/PrivacyTranslationAr.vue'
import PrivacyTranslationEn from './term-and-policy/privacy-policy-translation/PrivacyTranslationEn.vue'

import PublicContact from './contact-us/public-contact/PublicContact.vue';

import { mdiClose } from '@mdi/js'
import store from '@/store';

export default {
    components: {
        AppBarI18n, AppBarThemeSwitcher,
        ForgotStepOne, ForgotStepTwo, ForgotStepThree,
        TermConditionAr, TermConditionEn, PublicContact,
        PrivacyTranslationAr,
        PrivacyTranslationEn,
    },
    data: () => ({
        tab: null,
        step: 1,
        finished: false,
        modall: false,
        selectedLanguage: 'en',
        modalTerms: false,
        icons: {
            mdiClose
        },
        publicContact: false,
    }),
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        window.scrollTo(0, 0);
    },
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    methods: {
        nextStep()
        {
            this.step++;
            window.scrollTo(0, 0);
        },
        openPopup()
        {

            if (this.modall == false)
            {
                this.modall = true
            } else
            {
                this.modall = false
            }
        },

        closeDialog()
        {
            this.modall = false;
        },
        backStep()
        {
            if (this.step == 1)
            {
                this.backToSignIn();
                window.scrollTo(0, 0);
            }
            else
            {
                this.step--;
                window.scrollTo(0, 0);
            }
        },
        finishedForgot()
        {
            this.finished = true;
            router.push('/' + this.selectedLanguage + '/login')
            window.scrollTo(0, 0);
        },
        backToSignIn()
        {
            router.push('/' + this.selectedLanguage + '/login')
            window.scrollTo(0, 0);
        }
    },

    setup()
    {
        return {
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.en-consumer {
    position: absolute;
    color: #fff;
    top: 10px;
    left: 40px;
    right: 40px;
    font-size: 15px;
}

// .login-bg {
//     background-image: url('../assets/images/login.png');
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: right;
//     height: 100vh;
// }


.public-contact {
    width: 70%;
    // height: 400px;
    // overflow: hidden;
    margin: auto;
}


@media screen and (max-width: 809px) {
    .public-contact {
        width: 98%;
        // height: 95vh;
        // overflow: hidden;
    }
}

.en-forgotid {
    font-size: 31px;
    cursor: pointer;
    position: absolute;
    left: 0;
}
</style>
